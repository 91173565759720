import React, {useContext, useState, createContext} from 'react';
import _isNil from "lodash-es/isNil"
import { useQuery, useApolloClient } from '@apollo/client';

import {SIGN_IN, GET_CURRENT_USER, CHANGE_PASS} from 'query'


const ClientUserContext = createContext(null);

const ClientUserProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const [mainLoading, setMainLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [clientUserData, setClientUserData] = useState({})


  //auto load "user data"
  useQuery(GET_CURRENT_USER, {
    fetchPolicy: "no-catch",
    onError(err){
      setMainLoading(false)
    },
    onCompleted(userData) {
      if(!_isNil(userData.currentUser)){
        setClientUserData(userData.currentUser)
        setIsLoggedIn(true)
        setMainLoading(false)
      } 
    }
  });

  const signIn = async(email, password) => {
    try{
      let result = await apolloClient.mutate({
        mutation: SIGN_IN,
        variables: {email, password},
      })

      if(result.data.signIn === null) {
        alert('Wrong Password')
        return
      }
      localStorage.setItem('@bereachAuthToken', result.data.signIn.token)
      delete result.data.signIn.token
      setClientUserData(result.data.signIn)
      setIsLoggedIn(true)
      setMainLoading(false)
    } catch (err){
      console.error("ClientUserProvider signIn", err)
      alert(err)
      setMainLoading(false)
      throw err
    }
  }

  const signOut = async() => {
    localStorage.removeItem("@bereachAuthToken");
    setClientUserData(null)
    setIsLoggedIn(false)
  }

  const changePassword = async(password) => {
    try{
      await apolloClient.mutate({
        mutation: CHANGE_PASS,
        variables: {password:password},
      })
      return
    } catch (err){
      console.error("ClientUserProvider changePassword", err)
      setMainLoading(false)
      throw err
    }
  }

  return (
    <ClientUserContext.Provider
      value={{
        setMainLoading,
        mainLoading,
        isLoggedIn,
        clientUserData,
        setClientUserData,
        changePassword,
        signIn,
        signOut,
      }}>
      {children}
    </ClientUserContext.Provider>
  );
};

const useClientUser = () => {
  const clientUser = useContext(ClientUserContext);
  if (clientUser == null) {
    throw new Error('clientUser() called outside of a AuthProvider?');
  }
  return clientUser;
};

export {ClientUserProvider, useClientUser};


