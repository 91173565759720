import { gql } from '@apollo/client';
import {FEEDBACK_ATTRIBUTES} from './general'

const QR_CODE_ATTRIBUTES = gql`
  fragment qrCodeAttributes on QrCode {
    _id
    qr_code
    name
    company_id
    store_id
    store_name 
    timer
    type
    payment_types
  }

  fragment dinningTypeAttributes on DiningTypes {
    _id,
    value,
    label
    description,
    selectedBackgroundColor
  }

`

export const GET_QR_CODE = gql`
  query($company_id: ID){
    qrCode(company_id:$company_id){
      totalResult
      result {
        ...qrCodeAttributes
      }
      types {
        ...dinningTypeAttributes
      }
      
    }
  }${QR_CODE_ATTRIBUTES}
`;

export const BATCH_CREATE_QR_CODE = gql`
  mutation generateNewQrCodeBatch($input: CreateQrCodeInput){
    generateNewQrCodeBatch(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const UPDATE_QR_CODE = gql`
  mutation updateQrCode($input: QrCodeInput){
    updateQrCode(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const DELETE_QR_CODE = gql `
  mutation deleteQrCode($_ids: [ID]){
    deleteQrCode(_ids:$_ids){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`


