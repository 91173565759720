import React, {useContext, useState, createContext} from 'react'
import { useApolloClient } from '@apollo/client'
import {useStore} from 'contexts/StoreProvider'
import {GET_SALES_REPORT} from 'query'
import _isNaN from 'lodash-es/isNaN'

const SalesReportContext = createContext(null);

const SalesReportProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const {selectedStoreID} = useStore()
  const [salesReport, setSalesReport] = useState([])
  const [summary, setSummary] = useState({total:0, sarawakPay:0, cash:0, unpaid:0})

  const getSalesReport = async (store_id, from_date, to_date) => {
    try{
      if(store_id === null || store_id ===''){
        throw 'store_id cannot be empty or null'
      }
      let result = await apolloClient.query({
        query: GET_SALES_REPORT,
        variables: {
          store_id:store_id,
          from_date:from_date,
          to_date,
        },
      })
      setSalesReport(result.data.salesReport.result)
      await getSummary(result.data.salesReport.result)
      return result
    } catch (err){
      console.error("SalesReportProvider getSalesReport", err)
      throw err
    }
  
  }

  const getSummary = async (salesReportData) => {
    let total = 0
    let sarawakPay = 0
    let cash = 0
    let boost = 0
    let unpaid = 0
    let dineIn = 0
    let selfPickup = 0
    let takeAway = 0
    let delivery = 0

    if (salesReportData.length > 0) {
      for (let index in salesReportData){
        let result = salesReportData[index]

        if(!_isNaN(result.total_price)){
          total = total + parseFloat(result.total_price)
        }
        if (!_isNaN(result.total_price) && (result.payment_type === "SarawakPay-APP" || result.payment_type === "SarawakPay-web")){
          sarawakPay += parseFloat(result.total_price) 
        } else if(!_isNaN(result.total_price) && result.payment_type==="BOOST-APP"){
          boost += parseFloat(result.total_price)
        } else if(!_isNaN(result.total_price) && result.payment_type==="cash"){
          cash += parseFloat(result.total_price)
        } else if(!_isNaN(result.total_price) && result.payment_type==="none"){
          unpaid += parseFloat(result.total_price)
        }

        if(!_isNaN(result.total_price) && (result.order_type==="dine-in" || result.order_type==="dine-in-table")){
          dineIn = dineIn + parseFloat(result.total_price)
        }

        if(!_isNaN(result.total_price) && (result.order_type==="delivery")){
          delivery = delivery + parseFloat(result.total_price)
        }

        if(!_isNaN(result.total_price) && (result.order_type==="self-pickup" || result.order_type==="self-pickup-time" || result.order_type==="self-pickup-time-carplate" )){
          selfPickup = selfPickup + parseFloat(result.total_price)
        }

        if(!_isNaN(result.total_price) && (result.order_type==="take-away" )){
          takeAway = takeAway + parseFloat(result.total_price)
        }

      }
    }

    setSummary({total, sarawakPay, boost, cash, unpaid, dineIn, delivery, takeAway, selfPickup})
    return
}

  return (
    <SalesReportContext.Provider
      value={{
        salesReport,
        getSalesReport,
        selectedStoreID,
        summary
      }}>
      {children}
    </SalesReportContext.Provider>
  );
};

const useSalesReport = () => {
  const contx = useContext(SalesReportContext);
  if (contx == null) {
    throw new Error('useSalesReport() called outside of a SalesReportProvider?');
  }
  return contx;
};

export {SalesReportProvider, useSalesReport};


