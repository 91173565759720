import { gql } from '@apollo/client';

const COMPANY_ATTRIBUTES = gql`
  fragment companyAttributes on Company {
    _id
    display_name
    address
    phone
    email
    description
    main_image
    # gps_location
    # coordinates
    location
    show_coordinates
    type
    store_ids
    company_qr
    permissions
  }
`
// export const GET_COMPANY = gql`
//   query{
//     company{
//       ...companyAttributes
//     }
//   }${COMPANY_ATTRIBUTES}
// `;

export const GET_COMPANIES = gql`
  query{
    companies{
      ...companyAttributes
    }
  }${COMPANY_ATTRIBUTES}
`;


export const UPDATE_COMPANY = gql`
  mutation updateCompany($input:CompanyInput){
    updateCompany(input:$input){
      status
      msg
    }
  }
`;
