import React, {useContext, useState, createContext, useEffect} from 'react'
import { useApolloClient } from '@apollo/client'
import{useCompany} from 'contexts/CompanyProvider'
import {useStore} from 'contexts/StoreProvider'
import _isEmpty from 'lodash-es/isEmpty'
import {GET_QR_CODE, BATCH_CREATE_QR_CODE, DELETE_QR_CODE, UPDATE_QR_CODE} from 'query'

const QrCodeContext = createContext(null);

const QrCodeProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const { selectedStore, selectedStoreID } = useStore();
  const [diningType, setDiningType] = useState([]);
  const [qrCodeList, setQrCodeList] = useState([]);
  const [selectedCompanyID, setSelectedCompanyID] = useState("");

  useEffect (() => {
    if (selectedStore?.companies?.length > 0) {
      const defaultCompany = selectedStore?.companies[0];

      setSelectedCompanyID(defaultCompany['_id'])
      getQrCodeList(defaultCompany['_id'])
    }
  }, [selectedStore]);

  const getQrCodeList = async (company_id) => {
    try{
      if(company_id === null || company_id ===''){
        throw 'store_id cannot be empry or null'
      }
      let result = await apolloClient.query({
        fetchPolicy: "network-only",
        query: GET_QR_CODE,
        variables: {company_id:company_id},
      })
      console.log(result)
      setDiningType(result.data.qrCode.types)
      setQrCodeList(result.data.qrCode.result)
    } catch (err){
      console.error("QrCodeProvider getQrCodeList", err)
      throw err
    }
  
  }

  const createQrCode = async (data) => {
    var input = {...data, company_id: selectedCompanyID}
    try{
      let result = await apolloClient.mutate({
        mutation: BATCH_CREATE_QR_CODE,
        variables: { input: input},
      })
      if (selectedCompanyID === result['data']['generateNewQrCodeBatch']['payload'][0]['company_id']) {
        insertWithNewData("create", selectedCompanyID, result['data']['generateNewQrCodeBatch']['payload'])
      }
      return
    }catch(err){
      console.error("QrCodeProvider createQrCode", err)
      throw err
    }
  }

  const updateQrCode = async (data, index) => {
    let payload = {
      _id:data._id,
      name:data.name,
      company_id: data.company_id,
      timer: data.timer,
      type: data.type,
      payment_types: data.payment_types,
    }
    
    try {
      let result = await apolloClient.mutate({
        mutation: UPDATE_QR_CODE,
        variables: { input: payload },
      })
      insertWithNewData("update", index, data)
      return result
    }catch(err){
      console.error("QrCodeProvider updateQrCode", err)
      throw err
    }
  }

  const deleteQrCode = async (data) => {
    try{
      let result = await apolloClient.mutate({
        mutation: DELETE_QR_CODE,
        variables: {_ids:data},
      })
      //console.log(result)
      insertWithNewData("delete", null, data)
      return result
  
    }catch(err){
      console.error("QrCodeProvider deleteQrCode", err)
      throw err
    } 
  }

  const insertWithNewData = (type, idx, data) => {
    let values = [...qrCodeList];

    if(type === "create") {
      values = [...values, ...data]
    }

    if(type === "update") {
      values[idx] = {...values[idx], ...data}
    }

    if(type === "delete") {
      values = values.filter((item) => !data.includes(item._id))
    }

    setQrCodeList(values);
  }

  return (
    <QrCodeContext.Provider
      value={{
        selectedCompanyID,
        setSelectedCompanyID,
        qrCodeList,
        diningType,
        getQrCodeList,
        createQrCode,
        updateQrCode,
        deleteQrCode
      }}>
      {children}
    </QrCodeContext.Provider>
  );
};

const useQrCode = () => {
  const contx = useContext(QrCodeContext);
  if (contx == null) {
    throw new Error('useProduct() called outside of a QrCodeProvider?');
  }
  return contx;
};

export { QrCodeProvider, useQrCode };


