import { gql } from '@apollo/client';
import {FEEDBACK_ATTRIBUTES} from './general'

const OPTION_GROUP_ATTRIBUTES = gql`
  fragment optionGroupAttributes on OptionGroup {
    _id
    store_id
    group_name
    description
    max_select
    min_select
    sort
    options{
      name
      price
    }
  }
`

export const GET_OPTION_GROUP = gql`
  query($store_id: ID){
    optionGroups(store_id:$store_id){
      totalResult
      result {
        ...optionGroupAttributes
      }
      
    }
  }${OPTION_GROUP_ATTRIBUTES}
`;

export const SORT_OPTION_GROUP = gql`
  mutation sortOptionGroup($input:[OptionGroupSortInput]){
    sortOptionGroup(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const ADD_OPTION_GROUP = gql`
  mutation addOptionGroup($input:OptionGroupInput){
    addOptionGroup(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const UPDATE_OPTION_GROUP = gql`
  mutation updateOptionGroup($input:OptionGroupInput){
    updateOptionGroup(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const DELETE_OPTION_GROUP = gql`
  mutation deleteOptionGroup($_id:ID){
    deleteOptionGroup(_id:$_id){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;
