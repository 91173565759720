import React, {useContext, useState, createContext} from 'react';

const RegisterContext = createContext(null);

const RegisterProvider = ({children}) => {

  const [personalDetails, setPersonalDetails] = useState({
    fName: '',
    lName: '',
    pphone:'',
    email:'',
    password:'',
    rpassword:''
  })

  const [companyDetails, setCompanyDetails] = useState({
    cName:'',
    cAddress:'',
    cphone:'',
    cemail:'',
    cdescription:''
  })

  const [storeDetails, setStoreDetails] = useState({
    sdName:'',
    sfName:'',
    cRegister:'',
    semail:'',
    sAddress:'',
    sphone:'',
    website:'',
    sdescription:'',
    image:''
  })

  return (
    <RegisterContext.Provider
      value={{
        personalDetails, 
        setPersonalDetails,

        companyDetails, 
        setCompanyDetails,

        storeDetails,
        setStoreDetails,
      }}>
      {children}
    </RegisterContext.Provider>
  );
};

const useRegister = () => {
  const clientUser = useContext(RegisterContext);
  if (clientUser === null) {
    throw new Error('clientUser() called outside of a AuthProvider?');
  }
  return clientUser;
};

export {RegisterProvider, useRegister};


